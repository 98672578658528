@import '../../scss/settings/variable.scss';

.card-body {
  .material-table__wrap {
    .material-table__row {
      &:hover {
        background: none;
        cursor: default;
      }
      a {
        color: $color-accent;
        cursor: pointer;
        &:hover {
          color: $color-accent-hover;
        }
      }
    }
  }
  .material-table__pagination {
    float: none;
    width: 100%;
    .MuiTablePagination-toolbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .MuiTablePagination-spacer,
      .MuiTablePagination-selectRoot {
        display: none;
      }
    }
  }
}

.text_ellipsis {
  max-width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fake_link {
  color: $color-accent;
  cursor: pointer;
  &:hover {
    color: $color-accent-hover;
  }
}

.flag {
  img {
    width: 16px;
    height: 11px;
    background: url(../../shared/img/flags/flags.png) no-repeat;
  }
}
