@import '../../scss/settings/variable.scss';

.filters_container {
  min-height: 60px;
  padding-right: 10px;

  .select_container {
    min-width: 200px;
  }

  .filter_input {
    margin-left: 20px;
    min-width: 210px;
  }
}

.pois_container {
  height: calc(100% - 60px) !important;
  position: relative;
  flex: 1;
  width: 100%;

  .track-horizontal {
    display: none;
  }
}
