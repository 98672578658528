@import '../../scss/settings/variable.scss';

.tours_dashboard {
  flex: 1;
  .row {
    height: 100%;
    .col {
      display: flex;
      flex-direction: column;
      .page_title_container {
        margin-bottom: 20px;

        .page_title {
          color: $grey-dark;
          font-size: 20px;
          font-weight: 500;
        }

        .page_subtitle {
          color: $grey;
          font-size: 14px;
        }
      }

      .tours_container {
        position: relative;
        flex: 1;

        .track-horizontal {
          display: none;
        }
      }
    }
  }
}
