@import '../../scss/settings/variable.scss';

.inline-loader {
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background: $white-50;
  z-index: 2;
}

.full_page {
  bottom: 0;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.simple-loader {
  height: 0px !important;
  background: transparent !important;
}
