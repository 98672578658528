@import '../../scss/settings/variable.scss';

.message-container {
  color: $color-accent;
  font-size: 14px;
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: $white-50;
  z-index: 2;

  .message_btn {
    background: $color-accent;
    border: $color-accent-hover;
    border-radius: 4px;
    color: $white;
    margin-top: 5px;
    padding: 5px 10px;
    &:hover {
      background: $color-accent-hover;
    }
  }
}

.page-message {
  background: $color-accent-lighter !important;
  border: 1px solid $color-accent-50;
  border-radius: 4px;
  color: $color-accent-hover;
  bottom: 20px;
  height: auto;
  left: 50%;
  margin-left: -150px;
  padding: 10px;
  position: absolute;
  min-width: 300px;
  width: auto;
}
