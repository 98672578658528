@import '../../scss/settings/variable.scss';

.tour_card {
  background-color: $white;
  border-radius: 4px;
  margin: 15px 0;
  min-height: 220px;
  padding: 15px 13px;
  position: relative;
  overflow: hidden;

  &.inline {
    margin: 0 0 10px;
    min-height: auto;
    width: 100%;
  }

  .tour_card_title {
    margin-bottom: 10px;
    color: $grey-dark;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tour_info {
    font-size: 14px;
    color: $grey;
    text-transform: capitalize;
    span {
      font-weight: 500;
    }
  }

  .outline-link {
    border: 1px solid $color-additional;
    border-radius: 4px;
    color: $color-additional;
    cursor: pointer;
    font-weight: 500;
    margin-top: 30px;
    padding: 5px 10px;
    text-transform: uppercase;
    &:hover {
      color: $color-additional-hover;
      border: 1px solid $color-additional-hover;
    }
  }

  .indicators_container {
    position: absolute;
    bottom: 10px;

    .indicator {
      background: $color-accent-light;
      border-radius: 50%;
      height: 30px;
      margin-right: 5px;
      width: 30px;
      svg {
        fill: $color-accent-hover;
        height: 20px;
        width: 20px;
      }
    }
  }
}
