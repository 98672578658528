@import '../../scss/settings/variable.scss';

.map__marker-label {
  padding: 10px 5px;

  .map__marker-label-content {
    width: 200px;
    .map__marker-label-title {
      color: $grey-dark;
      font-size: 14px;
      font-weight: 500;
      height: 40px;
      line-height: 20px;
      margin-right: 20px;
      overflow: hidden;
    }

    .map__maker-label-close {
      margin-right: 5px;
      svg {
        fill: $grey;
        height: 20px;
        width: 20px;
      }
    }

    .button_container {
      .btn {
        margin: 5px 0;
        padding: 5px 15px;
      }
    }
  }
}
