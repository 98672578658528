@import '../../scss/settings/variable.scss';

.modal_container {
  .btn {
    text-transform: uppercase;
  }
}

.custom-modal {
  .modal-content {
    border-radius: 4px;
    overflow: hidden;
    .modal__header {
      background: $gray-lighter;
      color: $gray-dark;
      h4 {
        font-weight: 400;
      }
      button {
        color: $gray-dark;
      }
    }
  }
}
