@import '../../scss/settings/variable.scss';

.page__wrapper {
  padding: 20px;

  p {
    color: $grey-dark;
    font-size: 14px;
    strong {
      color: $grey-darker;
      font-size: 16px;
    }
    a {
      color: $grey-light;
    }
  }
  ul,
  ol {
    color: $grey-dark;
    list-style: disc;
    font-size: 14px;
    strong {
      color: $grey-darker;
      font-size: 16px;
    }
    a {
      color: $grey-light;
    }
  }
}
