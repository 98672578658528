@import '../../scss/settings/variable.scss';

.input_field_error {
  background-color: $color-red;
  color: $white;
  position: absolute;
  top: 60px;
  right: 0px;
  border-radius: 20px;
  padding: 0px 10px 0;
  font-size: 10px;
}
