@import '../../scss/settings/variable.scss';

.new_tour_form {
  .form__form-group {
    margin: 0 0 20px 0 !important;
    .form__form-group-label {
      color: $gray-dark;
      font-size: 14px;
    }
    .material-form__field {
      width: 100%;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: $gray-light !important;
    }
    .MuiInputLabel-outlined {
      font-size: 14px;
      transform: translate(14px, 16px) scale(1);
      &.MuiInputLabel-shrink {
        transform: translate(14px, -3px) scale(0.75);
      }
      &.MuiInputLabel-shrink:not(.Mui-error) {
        color: $color-accent;
      }
    }
  }
  .form__button-toolbar {
    width: 100%;
    button {
      width: auto;
    }
  }
}
