@import '../../scss/settings/variable.scss';

.filter_input {
  position: relative;
  .form__form-group-label {
    color: $gray-dark;
    font-size: 14px;
  }
  .material-form__field {
    width: 100%;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: $gray-light !important;
  }
  .MuiInputLabel-outlined {
    font-size: 14px;
    transform: translate(14px, 16px) scale(1);
    &.MuiInputLabel-shrink {
      transform: translate(14px, -3px) scale(0.75);
    }
    &.MuiInputLabel-shrink:not(.Mui-error) {
      color: $color-accent;
    }
  }
  .MuiOutlinedInput-input {
    padding: 12px 55px 12px 14px;
  }
  .buttons {
    height: 43px;
    position: absolute;
    right: 5px;
    svg {
      cursor: pointer;
      fill: $grey;
      height: 16px;
      width: 16px;
      &:hover {
        opacity: 0.7;
      }
      &.submit_icon {
        fill: $color-accent;
        height: 24px;
        margin-left: 10px;
        width: 24px;
      }
    }
  }
}
