@import '../../scss/settings/variable.scss';

.statistics_dashboard {
  flex: 1;
  .row {
    height: 100%;
    .col {
      display: flex;
      flex-direction: column;
      .page_title_container {
        margin-bottom: 20px;

        .page_title {
          color: $grey-dark;
          font-size: 20px;
          font-weight: 500;
        }

        .page_subtitle {
          color: $grey;
          font-size: 14px;
        }
      }

      .duration_container {
        margin-bottom: 20px;
        width: 100%;
      }

      .statistics_container {
        position: relative;
        flex: 1;

        .total_card {
          background: $white;
          border-radius: 4px;
          color: $color-additional-50;
          font-size: 20px;
          font-weight: 500;
          line-height: 32px;
          margin: 0 16px 20px;
          padding: 20px;
          text-transform: uppercase;

          .number {
            color: $color-additional;
            font-size: 60px;
            font-weight: 400;
            line-height: 60px;
            margin-right: 10px;
          }
        }

        .track-horizontal {
          display: none;
        }
      }
    }
  }
}
