@import '../../scss/settings/variable.scss';

.poi_container {
  margin: 0 15px 20px 0;
  position: relative;

  .sort_container {
    margin: 0 10px;
    .btn {
      background: none;
      border: 0;
      border-radius: 50%;
      height: 12px;
      min-width: 20px;
      padding: 0px;
      margin: 0px;

      &:disabled {
        opacity: 0.3;
      }

      &:before {
        background: none;

        svg {
          fill: $color-gray;
        }
      }

      svg {
        fill: $color-gray;
        height: 28px;
        margin: -12px 0 0 0;
        width: 28px;
      }
    }
  }

  .poi_img {
    flex-shrink: 0;
    border-radius: 4px 0 0 4px;
    height: 108px;
    overflow: hidden;
    position: relative;
    width: 108px;

    img {
      position: absolute;
      height: 108px;
      min-width: 108px;
      width: auto;
    }
  }

  .poi {
    background: $white;
    border-radius: 0 4px 4px 0;
    flex: 1;
    flex-basis: 0px;
    color: $grey;
    font-size: 14px;
    height: 108px;
    width: 0;
    padding: 10px 20px 10px 10px;

    .poi_title {
      color: $grey-dark;
      flex: 1;
      font-size: 14px;
      font-weight: 500;

      .poi_label {
        background: $color-accent;
        font-size: 10px;
        font-weight: 500;
        margin: 0 5px 0 0;
        text-transform: uppercase;
        line-height: 13px;
        padding: 3px 5px;
        @include directify($directions) {
          #{directed('left')}: 100%;
        }
      }
    }

    .text_ellipsis {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .duration {
      color: $color-additional;
      font-weight: bold;
    }
  }

  .vertical_buttons {
    height: 108px;
    width: 30px;
    position: absolute;
    right: -15px;
    .btn {
      background: $white;
      border: 3px solid $gray-lighter;
      border-radius: 50%;
      height: 30px;
      margin-left: 10px;
      min-width: 30px;
      padding: 0px;
      margin: 2px 0px;

      &:hover {
        border: 3px solid $gray-lighter;
      }

      &:before {
        background-color: $gray-lighter;
      }

      svg {
        fill: $color-additional-light;
        height: 16px;
        margin: 0;
        width: 16px;
      }
      &.remove_btn {
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}
