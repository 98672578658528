@import '../../scss/settings/variable.scss';

.error-container {
  color: $color-additional;
  font-size: 14px;
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: $white-50;
  z-index: 2;

  .close_btn {
    background: none;
    border: none;
    height: 23px;
    margin: 0 0 0 10px;
    padding: 0;
    width: 16px;
    svg {
      fill: $color-additional;
      height: 16px;
      width: 16px;
    }
  }
}

.page-error {
  background: $color-accent-lighter !important;
  border: 1px solid $color-accent-50;
  border-radius: 4px;
  color: $color-accent-hover;
  bottom: 20px;
  height: auto;
  left: 50%;
  margin-left: -150px;
  padding: 10px;
  position: absolute;
  min-width: 300px;
  width: auto;

  .close_btn {
    svg {
      fill: $color-accent;
    }
    &:hover {
      svg {
        fill: $color-accent-hover;
      }
    }
  }
}
