@import '../../scss/settings/variable.scss';

.container {
  z-index: 100;
  .customizer__wrap {
    display: flex;
    height: calc(100vh - 60px);
    flex-direction: column;
    top: 60px;
    .customizer__title-wrap {
      color: $gray-dark;
      border: none;
      padding: 0;
      h3 {
        font-size: 20px;
        font-weight: 400;
      }
      .customizer__close-btn {
        top: 8px;
        svg {
          fill: $gray-dark;
        }
      }
    }
    .customizer__caption {
      color: $grey;
      font-size: 12px;
    }
    .customizer__body {
      background: $gray-lighter;
      border-radius: 4px;
      flex: 1;
      overflow-x: hidden;
      padding: 10px 0 10px 10px;
      position: relative;

      .poi_container {
        margin-right: 20px;
      }
    }
  }
}
