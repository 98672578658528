@import '../../scss/settings/variable.scss';

.form__form-group {
  margin-top: 25px !important;
  .form__form-group-label {
    color: $gray;
  }

  .form__form-group-icon,
  .form__form-group-button {
    background: $gray-lighter !important;
    svg {
      fill: $color-additional;
    }
  }
}

.error_message {
  background-color: $color-red;
  color: $white;
  padding: 0px 10px 0px 10px;
  border-radius: 10px;
  font-weight: 400;
  text-align: center;
  margin: 0px auto;
}
