@import '../../scss/settings/variable.scss';

.profile_avatar {
  align-self: center;
  background: $color-blue;
  border-radius: 50%;
  color: $white;
  font-weight: 400;
  font-size: 40px;
  height: 80px;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  width: 80px;
  &.size-sm {
    font-size: 26px;
    height: 40px;
    width: 40px;
  }
}
